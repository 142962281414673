import './css/user.scss';
import {uimanager} from "../ui-manager";
import $ from 'jquery';

import QRCode from 'qrcodejs2';

(function ($) {

  'use strict';

  uimanager.add(function (el) {
    const $dom_element = $(el);

    $dom_element.find('.copy-to-clipboard-btn').addBack('.copy-to-clipboard-btn').click(function () {
      // find copy to clipboard area
      let area = $(this).closest('.copy-to-clipboard');

      // hide nontext view
      area.find('.copy-to-clipboard-nontextview').addClass('hidden');
      area.find('.qr-code-view').addClass('hidden');
      area.find('.qr-code-btn').removeClass('hidden');

      // show text view
      area.find('.copy-to-clipboard-textview').removeClass('hidden');

      // get textarea
      let text = area.find('textarea');

      // update text if applicable
      let text_source = area.find('.copy-to-clipboard-text-source');
      if (text_source.length > 0)
        text.text(text_source.first().text().trim());

      // select text
      text.select();

      // try to copy to clipboard
      let success;
      try {
        success = document.execCommand('copy');
      } catch (e) {
        // browser support seems to be missing
        success = false;
      }

      let tooltip = $(this).tooltip({
        'placement': 'top',
        'trigger': 'manual',
        'title': success ? 'Text kopiert' : 'Text bereit zum Kopieren'
      });
      tooltip.tooltip('show');

      setTimeout(function () {
        tooltip.tooltip('hide');
      }, 1500);
    });

    $dom_element.find('.qr-code-btn').addBack('.qr-code-btn').each(function () {
      // find copy to clipboard area
      let area = $(this).closest('.qr-code');

      const qr_code_container = $(area).find('.qr-code-container').get(0);
      qr_code_container.innerHTML = '';
      const qrcode = new QRCode(qr_code_container, {
        text: '',
        width: 300,
        height: 300
      });

      const $text = $(area).find('textarea.qr-code-text');
      const $mobileNumber = $(area).find('[name="mobile_number"]');

      const update_qrcode = function () {
        const recipient = $mobileNumber.val().trim().replace(' ', '');
        const text = $text.val().trim();

        const qrtext = `SMSTO:${recipient}:${text}`;
        // const qrtext = `sms:${recipient}?body=${encodeURIComponent(text)}`;

        if (qrtext) {
          qrcode.makeCode(qrtext);
        } else {
          qrcode.clear();
        }
      };

      $mobileNumber.change(update_qrcode);
      $mobileNumber.keyup(update_qrcode);
      $text.change(update_qrcode);
      $text.keyup(update_qrcode);

      $(this).click(function () {
        // get textarea
        let qr_code_text = area.find('.qr-code-text-source');
        if (qr_code_text.length > 0) {
          $text.text(qr_code_text.first().text().trim());
        }

        // hide nontext view
        area.find('.copy-to-clipboard-nontextview').addClass('hidden');
        area.find('.copy-to-clipboard-textview').addClass('hidden');
        area.find('.qr-code-btn').addClass('hidden');

        // show text view
        area.find('.qr-code-view').removeClass('hidden');

        update_qrcode();
      });
    });
  });
})($);
